import { useEffect, useRef } from "preact/hooks";

export type TextScrollRevealProps = {
    text: string,
    textClass: string
}

export default function TextScrollReveal({
    text, textClass
}: TextScrollRevealProps) {
    const wordRefs = useRef<HTMLSpanElement[]>([]);

    const onScroll = () => {
        const allWords = wordRefs.current;
    
        allWords.forEach((word) => {
            const parentRect = word.parentElement?.getBoundingClientRect();
            if (parentRect && parentRect.top < window.innerHeight / 2) {
                const { left, top } = word.getBoundingClientRect();
                const adjustedTop = top - (window.innerHeight / 2);
                let opacityValue = 1 - ((adjustedTop * 0.01) + (left * 0.001));
                opacityValue = opacityValue < 0.1 ? 0.1 : opacityValue;
                opacityValue = opacityValue > 1 ? 1 : parseFloat(opacityValue.toFixed(3));
                word.style.opacity = opacityValue.toString();
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', () => onScroll())

        return () => window.removeEventListener('scroll', onScroll)
    }, [])



    return (
        <div>
            {text.split('').map((t: string, i: number) => (
                <span ref={el => {if (el) wordRefs.current[i] = el}} className={ textClass || 'text-crisp-white' } key={t + ' ' + i} >{t}</span>
            ))}
        </div>
    );
    
}