import {h} from 'preact';
import register from 'preact-custom-element';
import TextScrollReveal, { type TextScrollRevealProps } from '@/components/TextScrollReveal'

const renderTextScrollReveal = ({ text, textClass }: TextScrollRevealProps) => {
    return h(TextScrollReveal, { text, textClass })
}


register(renderTextScrollReveal, 'text-scroll-reveal', ['text', 'text-color']);
